import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    wallets: null,
    total: 0,
    totalBalance: 0,
    totalWalletBalance: 0,
    filter: {
        page: 1,
        pageSize: 10,
        searchKey: "",
        startDate: null,
        finishDate: null,
    },
};

const updateWallets = (state, action) => {
    return updateState(state, {
        wallets: action.wallets,
        total: action.total,
        totalBalance: action.totalBalance,
        totalWalletBalance: action.totalWalletBalance,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_WALLETS:
            return updateWallets(state, action);
        case actionTypes.UPDATE_WALLET_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
