/* eslint-disable max-len */
export const TRANSLATIONS_FR = {
    // COMMON
    fieldRequired: "Ce champ est obligatoire",
    fieldInvalid: "Ce champ est invalide",
    fieldMaxLimit: "Moins de {{limit}} caractères sont requis",
    fieldMaxValueLimit: "La valeur doit être inférieure à {{limit}}",
    fieldMinValueLimit: "La valeur doit être supérieure à {{limit}}",
    fieldEmailDuplicated: "E-mail dupliqué",
    fieldConfirmPasswordCorrectly: "Confirmer correctement le mot de passe",

    success: "Succès",
    warning: "Avertissement",
    error: "Erreur",

    // HEADER
    myProfile: "Mon profil",
    signOut: "Se déconnecter",

    // SIDER
    dashboard: "Tableau de bord",
    users: "Utilisateurs",
    devices: "Dispositifs",
    balance: "Équilibre",
    transactions: "Transactions",
    withdrawals: "Retraits",
    commissions: "Commissions",
    configuration: "Configuration",
    store: "Magasin",
    products: "Des produits",
    orders: "Ordres",

    // LOGIN
    email: "E-mail",
    password: "Mot de passe",
    keepMeSignedIn: "Gardez-moi connecté",
    signIn: "S'identifier",
    getCodeFromYourEmail: "Obtenez le code de votre e-mail",
    getCodeFromYourAuthenticatorApp: "Obtenez le code de votre application d'authentification",
    enterCodeFromYourEmail: "Entrez le code de votre email",
    verify: "Vérifier",
    resendCode: "Renvoyer le code",
    verificationCodeSentSuccessfully: "Le code de vérification a été envoyé avec succès",
    accountSuspendedError:
        "Ce compte est suspendu, veuillez contacter l'assistance à <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",

    // DASHBOARD
    total: "Total",
    wallet: "Portefeuille",
    incoming: "Entrant",
    commission: "Commission",
    withdrawal: "Retrait",
    consumption: "Consommation",
    monthly: "Mensuel",
    daily: "Quotidien",
    inboundCapacity: "Capacité entrante",
    outboundCapacity: "Capacité sortante",

    // USERS
    confirmDeleteUser: "Voulez-vous vraiment supprimer cet utilisateur ?",
    delete: "Supprimer",
    cancel: "Annuler",
    suspendUser: "Suspendre l'utilisateur",
    reason: "Raison",
    suspend: "Suspendre",
    newUser: "Nouvel utilisateur",
    searchByEmailOrFullname: "Recherche par e-mail ou nom complet",
    selectRole: "Sélectionnez le rôle",
    all: "Tout",
    startDate: "Date de début",
    finishDate: "Date de fin",
    fullname: "Nom et prénom",
    role: "Rôle",
    phone: "Téléphone",
    birthday: "Anniversaire",
    createdAt: "Créé à",
    isActive: "C'est actif",
    active: "Actif",
    inactive: "Inactif",
    suspended: "Suspendu",
    yes: "Oui",
    no: "Non",
    restore: "Restaurer",
    confirmRestoreUser: "Êtes-vous sûr de restaurer ce compte utilisateur ?",
    userRole1: "Super gestionnaire",
    userRole2: "Directeur",
    userRole3: "Administrateur",
    userRole4: "Membre",
    edit: "Modifier",
    resetPassword: "Réinitialiser le mot de passe",
    noUser: "Aucun utilisateur",
    updateUser: "Mettre à jour l'utilisateur",
    createUser: "Créer un utilisateur",
    saveChanges: "Sauvegarder les modifications",
    create: "Créer",
    confirmPassword: "Confirmez le mot de passe",
    reset: "Réinitialiser",
    lastLogin: "Dernière connexion",
    emailVerified: "Email verifié",
    phoneVerified: "Téléphone vérifié",

    // DEVICE
    search: "Recherche",
    paringCode: "Code d'appariement",
    marketType: "Type de marché",
    printPurchase: "Achat d'impression",
    receiveTip: "Recevoir un pourboire",
    confirmDeleteDevice: "Êtes-vous sûr de supprimer cet appareil ?",
    noDevices: "Aucun appareil",
    selectUser: "Sélectionner un utilisateur",

    // BALANCE
    adminEmail: "Courriel de l'administrateur",
    adminName: "Nom de l'administrateur",
    noBalance: "Pas de solde",
    walletBalance: "Solde du portefeuille",

    // TRANSACTIONS
    downloadAsExcel: "Télécharger sous Excel",
    date: "Date",
    admin: "Administrateur",
    company: "Entreprise",
    device: "Appareil",
    description: "Description",
    amount: "Montant",
    currency: "Monnaie",
    noTransactions: "Aucune transaction",
    outboundLiquidity: "Liquidité sortante",
    inboundLiquidity: "Liquidité entrante",
    hashLog: "Journal de hachage",

    // WITHDRAWALS
    selectStatus: "Sélectionnez le statut",
    withdrawalStatus_initiated: "Initié",
    withdrawalStatus_in_review: "En revue",
    withdrawalStatus_in_process: "En cours",
    withdrawalStatus_finished: "Fini",
    withdrawalStatus_rejected: "Rejeté",
    withdrawalStatusDescription_initiated:
        "La demande de retrait est créée, en attente de l'examen par le gestionnaire",
    withdrawalStatusDescription_in_review: "La demande de retrait est en cours d'examen par le responsable",
    withdrawalStatusDescription_in_process: "La demande de retrait est en cours",
    withdrawalStatusDescription_finished: "Le processus de retrait est terminé",
    withdrawalStatusDescription_rejected: "La demande de retrait est rejetée",
    selectManager: "Sélectionnez le gestionnaire",
    bank: "Banque",
    account: "Compte",
    status: "Statut",
    manager: "Directeur",
    seeBankDetail: "Voir les coordonnées bancaires",
    seeBitcoinAddressDetail: "Voir le détail de l'adresse bitcoin",
    noWithdrawal: "Pas de retrait",
    setAsInReview: "Définir comme En cours d'examen",
    setAsInProcess: "Définir comme En cours",
    finishWithdrawal: "Terminer le retrait",
    approveWithdrawal: "Approuver le retrait",
    confirmApproveLightningWithdrawal:
        "Etes-vous sûr d'approuver cette demande de retrait ? Une fois que vous aurez approuvé, la demande de retrait sera traitée et il s’agira de l’opération finale.",
    rejectWithdrawal: "Refuser le retrait",
    bankAccountDetail: "Coordonnées bancaires",
    bankName: "Nom de banque",
    swiftCode: "Code rapide",
    accountNumber: "Numéro de compte",
    accountName: "Nom du compte",
    address: "Adresse",
    zipCode: "Code postal",
    close: "Fermer",
    bitcoinAccountDetail: "Détail du compte Bitcoin",
    nickName: "Surnom",
    lightningAddress: "Adresse Lightning",
    lightningAddressDetail: "Lightning Détails de l'adresse",

    // COMMISSIONS
    commissionType: "Type de commissions",
    minimumPurchase: "Achat minimal",
    commissionRunOverThisAmount: "Commission exécutée sur ce montant",
    fixCommissionAmount: "Fixer le montant de la commission",
    commissionPercentage: "Pourcentage de commission",
    noCommission: "Aucune commission",
    updateCommission: "Commission de mise à jour",
    save: "Sauvegarder",

    // CONFIGURATION
    baseCommission: "Commission de base",
    marketTypes: "Types de marché",
    name: "Nom",
    noMarketTypes: "Aucun type de marché",
    newMarketType: "Nouveau type de marché",
    createMarketType: "Créer un type de marché",
    updateMarketType: "Mettre à jour le type de marché",
    confirmDeleteMarketType: "Êtes-vous sûr de supprimer ce type de marché ?",

    // RODPAY WITHDRAWAL
    makeWithdrawal: "Effectuer un retrait",

    // PROFILE
    generalInformation: "Informations générales",
    avatar: "Avatar",
    security: "Sécurité",
    "2fa": "Authentification à deux facteurs",
    "2faDescription":
        "L'authentification à deux facteurs (2FA) est une méthode de sécurité utilisée pour protéger votre compte www.rodpay.io. L’objectif principal de l’authentification à deux facteurs est d’ajouter une couche de sécurité supplémentaire au-delà du mot de passe traditionnel. En utilisant le courrier électronique comme deuxième facteur d'authentification, même si quelqu'un parvient à obtenir votre mot de passe, il ne pourra pas accéder à votre compte sans avoir accès à votre courrier électronique pour obtenir le code.",
    allow2fa: "Activer l'authentification à deux facteurs (2FA)",
    setup2FASuccess: "La configuration de l'authentification à deux facteurs (2FA) a réussi",
    confirmDisable2fa: "Êtes-vous sûr de désactiver l'authentification à deux facteurs (2FA) ?",
    disable2fa: "Désactiver l'authentification à deux facteurs (2FA)",
    inputCode: "Code d'entrée",
    "2faSetupGuide":
        "Veuillez scanner le code QR ci-dessous à l'aide de votre application d'authentification telle que Google Authenticator et saisir le code.",
    passwordRule: "8 caractères et au moins 1 caractère spécial(!@#$&*) sont requis",

    // USER
    knowYourCustomer: "Connaissez votre client",
    nationalID: "Carte d'identité",
    nationalIDNotUploaded: "La pièce d'identité nationale n'est pas téléchargée",
    download: "Télécharger",
    approve: "Approuver",
    reject: "Rejeter",
    approved: "Approuvé",
    rejected: "Rejeté",
    companyDUNS: "Société DUNS",
    companyDUNSNotUploaded: "Le DUNS de l'entreprise n'est pas téléchargé",

    // USER LOG
    actionLogs: "Journaux d'actions",
    action: "Action",
    model: "Modèle",
    detail: "Détail",
    user_action_create: "Créer",
    user_action_update: "Mise à jour",
    user_action_delete: "Supprimer",
    user_action_soft_delete: "Supprimer",
    user_action_restore: "Restaurer",
    user_action_reset_password: "Réinitialiser le mot de passe",
    user_action_tfa_enable: "Activer 2FA",
    user_action_tfa_disable: "Désactiver 2FA",
    user_action_update_profile: "Mettre à jour le profil",
    user_action_invoice_paid: "Facture payee",
    user_action_invoice_expired: "Facture expirée",
    user_action_withdrawal_in_review: "Examen du retrait",
    user_action_withdrawal_in_process: "Procéder au retrait",
    user_action_withdrawal_finished: "Terminer le retrait",
    user_action_withdrawal_rejected: "Refuser le retrait",

    // PRODUCTS
    image: "Image",
    price: "Prix",
    stock: "Action",
    newProduct: "Nouveau produit",
    noProduct: "Aucun produit",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer?",
    createProduct: "Créer un produit",
    updateProduct: "Mettre à jour le produit",
    selectFile: "Choisir le dossier",
    totalOrders: "Total des commandes",
    totalReviews: "Total des avis",
    files: "Des dossiers",
    createNewFile: "Créer un nouveau fichier",
    type: "Taper",
    url: "URL",
    updateFile: "Fichier de mise à jour",
    file: "Déposer",
    pdf: "PDF",
    video: "Vidéo",
    weight: "Poids",
    rank: "Rang",
    noFile: "Pas de fichier",
    user: "Utilisateur",
    noReview: "Aucun avis",
    order: "Commande",

    // ORDERS
    initiated: "Initié",
    paid: "Payé",
    shipping: "Expédition",
    succeeded: "Réussi",
    canceled: "Annulé",
    orderDetail: "Détails de la commande",
    needToStartDelivery: "Besoin de démarrer le processus de livraison",
    in_process: "En cours",
    shipment: "Expédition",
    note: "Note",
    country: "Pays",
    state: "État",
    city: "Ville",
    apartment: "Appartement",
    postalCode: "Code Postal",
    product: "Produit",
    quantity: "Quantité",
    transaction: "Transaction",
    noTransactionHistory: "Aucun historique de transactions",
    cancelOrder: "Annuler la commande",
    startDelivery: "Commencer la livraison",
    confirmCancelOrder: "Etes-vous sûr d'annuler cette commande ?",
    confirm: "Confirmer",
    method: "Méthode",
    unpaid: "Non payé",
    paidAt: "Payé à",
    reviews: "Commentaires",
    rating: "Notation",
    message: "Message",
};
