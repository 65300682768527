import styled from "styled-components";

export const BHTable2 = styled.table`
    width: 100%;

    border-collapse: collapse;

    overflow: hidden;

    thead {
        tr {
            th {
                padding: 7px 0 7px 7px;

                text-align: left;
                vertical-align: middle;

                font-size: 15px;
                color: ${(props) => props.theme.colorDark};
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 7px 0 7px 7px;

                vertical-align: middle;

                color: ${(props) => props.theme.colorDark};
            }
        }
    }
`;
