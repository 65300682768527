import * as actionTypes from "../actionTypes";

import { logout } from "./auth";

import { getErrorMessage } from "../../../utils/helpers";

export const updateSiderCollapsed = (siderCollapsed) => {
    return {
        type: actionTypes.UPDATE_SIDER_COLLAPSED,
        siderCollapsed,
    };
};

export const updateLoading = (loading) => {
    return {
        type: actionTypes.UPDATE_LOADING,
        loading,
    };
};

export const updateMessage = (type, content) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        message: {
            type,
            content,
        }
    };
};

export const errorManageHandler = (e, callback = null) => {
    console.log(e);
    return dispatch => {
        const errorMessage = getErrorMessage(e);
        dispatch(updateMessage("error", errorMessage));
        if (e.response.status === 401) {
            dispatch(logout());
        }
        if (callback) callback();
        dispatch(updateLoading(false));
    };
};