import styled from "styled-components";
import { rgba } from "polished";

export const BHTable1 = styled.table`
    width: 100%;

    border-collapse: collapse;

    overflow: hidden;

    thead {
        tr {
            th {
                padding: 12px;

                text-align: left;
                vertical-align: middle;

                font-size: 15px;
                color: ${(props) => props.theme.colorBlack};

                background-color: ${(props) => props.theme.thBackgroundColor};
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                td {
                    background-color: ${(props) => rgba(props.theme.colorDarkGray, 0.2)};
                }
            }

            td {
                padding: 12px;

                vertical-align: middle;

                color: ${(props) => props.theme.siderMenuTextColor};

                border-bottom: 1px solid ${(props) => rgba(props.theme.colorBlack, 0.08)};
            }
        }
    }

    tfoot {
        tr {
            td {
                padding: 12px;

                text-align: left;
                vertical-align: middle;

                font-size: 15px;
                color: ${(props) => props.theme.siderMenuTextColor};

                background-color: ${(props) => rgba(props.theme.colorGray, 0.5)};
            }
        }
    }
`;
