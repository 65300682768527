import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    siderCollapsed: false,
    theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
    loading: false,
    message: {
        type: null,
        content: null,
    },
};

const updateSiderCollapsed = (state, action) => {
    return updateState(
        state,
        {
            siderCollapsed: action.siderCollapsed,
        }
    );
};

const updateLoading = (state, action) => {
    return updateState(
        state,
        {
            loading: action.loading,
        }
    );
};

const updateMessage = (state, action) => {
    return updateState(
        state,
        {
            message: action.message,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_SIDER_COLLAPSED: return updateSiderCollapsed(state, action);
    case actionTypes.UPDATE_LOADING: return updateLoading(state, action);
    case actionTypes.UPDATE_MESSAGE: return updateMessage(state, action);
    default: return state;
    }
};

export default reducer;
